import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/UpdateLayout.js";
import ListItem from 'components/LinkCard/ListItem';
import List from '../../../components/LinkCard/List';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "more-components",
      "style": {
        "position": "relative"
      }
    }}>{`More components!`}</h2>
    <p>{`We're in our final stretch of releasing most of the base building blocks when it
comes to components. This release tackles some of the more functionally
complicated components.`}</p>
    <List mb={8} items={[{
      title: 'DatePicker',
      description: '',
      to: `/components/datepicker/code`
    }, {
      title: 'Dialog',
      description: '',
      to: `/components/dialog/code`
    }, {
      title: 'BrandedHeading',
      description: '',
      to: `/components/brandedheading/code`
    }, {
      title: 'Loader',
      description: '',
      to: `/components/loader/code`
    }, {
      title: 'IconButton',
      description: '',
      to: `/components/iconbutton/code`
    }, {
      title: 'Textarea',
      description: '',
      to: `/components/textarea/code`
    }]} mdxType="List" />
    <h2 {...{
      "id": "passing-down-ref",
      "style": {
        "position": "relative"
      }
    }}>{`Passing down ref`}</h2>
    <p>{`To enable interaction with third party libraries or to enable any other case
where you need direct access the the DOM node inside a component, we're now
passing down the ref property to the component.`}</p>
    <p>{`The node to which the ref is forwarded, depends on the component. In some cases,
it is passed onto the wrapper, in other cases on a child node/component. E.g.
with TextField, the input element will have the forwarded ref. This is based on
subjective judgement, taking into account what the expected behavior would be.
Be sure to notify us if something does not behave as you'd expect.`}</p>
    <List my={8} mdxType="List">
  <ListItem title="v0.6.0 changelog" description="Get a full overview of what's changed in the changelog" to="/updates/changelog" mdxType="ListItem" />
    </List>
    <h2 {...{
      "id": "looking-forward",
      "style": {
        "position": "relative"
      }
    }}>{`Looking forward`}</h2>
    <p>{`Every release, we'll try to give you an insight on our plans for the next
release. That way you know what to expect, and see how you can adapt to our new
features.`}</p>
    <p>{`Next release(s) will focus on finishing the base set of building blocks that are
considered widely used components. These include but are not limited to Lists,
Listitems, Steppers, ...`}</p>
    <p>{`When the base set of building blocks is complete, we'll start looking toward
realeasing our first really stable release, denoted by the 1.0.0 version. This
will include some breaking changes (e.g. deprecated tokens). As with all
breaking changes, we'll alway try to indicate it as deprecated for a while,
after which it will be effectively removed in the next major release.`}</p>
    <p>{`Included in the next release will also be the adaptation of our idea around
spacing. Up until now, we've always proclaimed that we didn't want to include
spacing (e.g. margin, ...) in our components directly, and as such introduced
the Box component. In the previous release, we added the clone prop to the Box.
While this was technically a step in the right direction (lowering the amount of
divs), it still wasn't 100% right on the DX department.`}</p>
    <p>{`We're now going to pivot to a styled-system approach, where you can add the
necessary spacing tokens on a component directly. While the restrictions for
what tokens will be accepted will still apply, it should allow you to accomplish
the same effect with much less code. Who doesn't like that?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      